@import 'settings/_colors';
@import 'settings/_fonts';

@keyframes down-slide {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes up-slide {
  0% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}

button.hamburger {
  display: inline-block;
  position: fixed;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 1.5% 1%;
  z-index: 3;
  span.hamburger-box {
    width: 30px;
    height: 24px;
    display: inline-block;
    position: relative;
    span.hamburger-inner, span.hamburger-inner:before, span.hamburger-inner:after {
      width: 100%;
      height: 3px;
      background-color: $text;
      position: absolute;
      left: 0;
    }
    span.hamburger-inner {
      top: 50%;
      transform: translateY(-50%);
      transition: background-color .3s .1s ease-in-out;
    }
    span.hamburger-inner:before, span.hamburger-inner:after {
      content: "";
      transition: transform .3s .2s ease-in-out;
    }
    span.hamburger-inner:before {
      top: -10px;
    }  
    span.hamburger-inner:after {
      top: 10px;
    }
  }
}


section.menu {
  height: 80px;
    width: 100vw;
    background-color: white;
    opacity: 1;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
    div.navi-container {
      width: 100%;
      nav {
        display: flex;
        justify-content: center;  
        div.navi-menu-container{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 0;
          ul.navi-menu {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 3;
            height: 260px;
            width: 170px;
            background-color: $alt2;
            display: flex;
            flex-direction: column;
            font-family: $font-alt;
            color: $text;
            font-size: 1.3em;
            font-weight: bold;
            text-transform: uppercase;
            padding: 20px;
            opacity: 0.9;
            margin-top: 48px;
            a {
              padding: 3px 0;
            }
          }
          ul.hide {
            display: none;
          }
        }
        div.navi-contact-container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2%;
          ul.navi-contact {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-family: $font-alt;
            font-size: 0.9em;
            color: $text;
            padding: 5px 0;
            li {
              display: flex;
              padding: 2px 0;
              i {
                color: $alt;
                padding: 0 5px;
                padding-top: 3px;
              }
              p {
                margin-bottom: 0;
              }
              a {
                margin-bottom: 0;
                color: $text;
                text-decoration:none
              }
            }
          }
        }
      }
    }
}
button.hamburger-active {
  span.hamburger-box {
    span.hamburger-inner {
      background-color: transparent  !important;
    }
    span.hamburger-inner:before {
      transform: translateY(10px) rotate(45deg);
    }
    span.hamburger-inner:after {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
}


@media screen and (min-width: 768px){
  section.menu {
    height: 85px;
    div.navi-container{
      display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
      nav{
        display: flex;
          justify-content: center;
          align-items: center;
        div.navi-menu-container{
          width: 100%;
          ul.navi-menu{
            height: 290px;
            a {
              padding: 5px 0;            }
          }
        }
        div.navi-contact-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          margin-top: 0;
          ul.navi-contact {
            width: 100vw;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            color: $text;
            font-size: 1.1em;
            li {
              display: flex;
              padding: 5px 15px;
              i {
                color: $alt;
                padding: 0 6px;
                padding-top: 3px;
              }
            }
          }
        }
      }
    }    
  }
}

@media screen and (min-width: 992px) {
button.hamburger {
  display: none;
}
  section.menu {
    position: fixed;
    width: 100vw;
    height: 70px;
    div.navi-container {
      display: flex;
      align-items: center;
      justify-content: center;
      nav {
        display: flex;
        width: 100%;
        height: 100%;
        div.navi-menu-container {
          display: flex;
          width: 60%;
          align-items: center;
          padding-left: 3%;
            div.navi-logo {
              display: flex;
              background-image: url("./../assets/commsoft_logo.png");
              height: 8vh;
              width: 24vw;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              justify-content: center;
            }
            ul.navi-menu {
              position: relative;
              height: auto;
              width: 100%;
              animation: 1s ease-out 2s 1 up-slide;
              display: flex;
              background-color: transparent;
              flex-direction: row;
              color: $text;
              align-items: center;
              margin: 0 0;
            }
            ul a,
            a:visited {
              font-family: $font-alt;
              font-size: 0.5em;
              font-weight: bold;
              color: $text;
              margin: 0 5px;
              border: 2px solid white;
              text-transform: uppercase;
              display: flex;
            }
            ul a:hover {
              cursor: pointer;
              transition: 0.3s;
              color: $text;
              border-bottom: 2px solid $alt;
            }
          }
        
        div.navi-contact-container {
          display: flex;
          width: 40%;
          padding-right: 4%;
          ul.navi-contact {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $text;
            font-size: 0.7em;
            li {
              display: flex;
              margin: 0 -8px;
              i {
                color: $alt;
                margin: 0 3px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  section.menu {
    height: 90px;
    div.navi-container {
      nav {
        div.navi-menu-container {
          padding-top: 0;
          margin-top: 0;
          align-items: center;
          width: 65%;
          div.navi-logo {
            background-size: contain;
            background-repeat: no-repeat;
            height: 8vh;
            width: 24vw;
          }
            ul.navi-menu {
              height: auto;
            }
            ul a,
            a:visited {
              margin: 0 5px;
              font-size: 0.7em;
            }
          }
        
        div.navi-contact-container {    
          justify-content: flex-start;
          align-items: center;     
          width: 35%;
          ul.navi-contact {
            font-size: 0.9em;
            padding-top: 0;
          margin-top: 0;
            li {
              i {
                margin: 0 5px;
              }
            }
          }
        }
      }
    }
  }
}