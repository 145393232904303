section.lightmind-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 25vh;
    width: 100vw;
    background-color: transparent;
    z-index: 2;
    div.lightmind-nav {
        height: 35%;
        width: 100vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        a.lightmind-back-button-s {
          width: 25%;
          font-size: 1.3em;
          cursor: pointer;
          padding: 10px;
          i {
            padding: 10px;
          }
        }
        a.lightmind-back-button {
          display: none;
        }
        a:hover {
          color: $alt;
        }
        a:visited {
          color: $text;
        }
        div.lightmind-logo {
          background-image: url('./../../assets/commsoft_logo.png');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 85%;
          width: 50%;
        }
        div.lightmind-centering-element {
          width: 25%;
          height: auto;
        }
        button {
          padding: 15px;
        }
    }
    section.buttons {
        height: 65%;
        width: 100vw;
        background-image: url('./../../assets/background_test2.jpg');
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        div.buttons-container {
            display: flex;
            justify-content: center;
            align-items: center;
            div.button-content {
              height: 9vh;
              width: 16vw;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: white;
              box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
              margin: 13px;
              padding: 10px;
              transition: transform .2s ease;
              i {
                font-size: 3.5vh;
                color: $text;
                padding: 2px;
              }
              h2 {
                display: none;
              }
            }
          }
        div.button-content:hover {
          transform: scale(1.1);
        }
        div.button-content:active {
          transform: scale(1.1);
          i {
            color: $alt;
          }
        }
    }
}

@media screen and (min-width: 768px) {
  section.lightmind-menu {
    div.lightmind-nav {
      height: 30%;
    }
    section.buttons {
      height: 70%;
      div.buttons-container {
        div.button-content {
          min-height: 140px;
        }
      }
     
      
    }
  }
}


@media screen and (min-width: 992px) {
  section.lightmind-menu {
    height: 32vh;
    div.lightmind-nav {
      height: 25%;
      a.lightmind-back-button {
        display: block;
        width: 25%;
        font-size: 1.3em;
        cursor: pointer;
        padding: 10px;
        text-transform: uppercase;
        padding-left: 5%;
        i {
          color: $text;
          padding: 15px;
        }
      }
      a.lightmind-back-button-s {
        display: none;
      }
    }
    section.buttons {
      height: 75%;
      div.buttons-container {
          div.button-content {
            height: 16vh;
            width: 20vw;
            max-width: 300px;
            max-height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
            margin: 30px;
            padding: 15px;
            transition: transform .2s ease;
            i {
              font-size: 4.6vh;
              color: $alt3;
              padding: 15px;
            }
            h2 {
              display: inline;
              font-family: $font-alt;
              font-size: 1.1em;
              color: $alt;
              font-weight: 1000;
              text-align: center;
              text-transform: uppercase;
            }
          }
      }
    }
  }
}
 