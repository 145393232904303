.imgFixedRatio{
    object-fit: contain;
    max-width: 100%;
    
}

.divPaddingConst{
    
}



div.users{
    margin-bottom: 50px;
    
    h2{
        font-family: $font-alt;
        font-size: 1.5em;
        font-weight: bold;
        color: $text;
        text-transform: uppercase;
        text-align: center;
        padding: 30px;
        letter-spacing: 1.1px;
    }
    img {
        height: 170px;
        width: 170px;
        display: block;
        margin:0 auto;
    }
    .slick-list {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    .slick-prev {
        z-index: 1;
        left: 20px !important;
    }
    .slick-next {
        z-index: 1;
        right: 20px !important;
    }
    .slick-next::before, .slick-prev::before{
        color: $text;
        font-size: 30px;
    }
}

@media screen and (min-width: 768px) {
    div.users {
        padding: 0 20px;
        h2 {
            font-size: 1.8em;
        }
    }
}

@media screen and (min-width: 992px) {
    div.users {
        h2{
            font-size: 1.6em;
        }
    }
}


@media screen and (min-width: 1200px) {
    div.users {
        h2{
            font-size: 1.8em;
        }
        padding: 3% 15%;
        .slick-list {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

    }
}