* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
html, body {
  height: 100vh;
  width: 100vw;
}

body {
  font-family: $font-base;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background: $background;
}

::-webkit-scrollbar-thumb {
  border: 6px solid $background;
  background: $alt2;
}
