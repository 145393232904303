$marginTop100: -50px;

#topToBottom {
    animation: 2s linear 2s 1 topToBottom;
    -webkit-animation: 2s linear 2s 1 topToBottom;
    -moz-animation: 2s linear 2s 1 topToBottom;
    -o-animation: 2s linear 2s 1 topToBottom;
    -ms-animation: 2s linear 2s 1 topToBottom;
  }
  @keyframes topToBottom {
    0% {
      margin-top: -30%;
    }
    100% {
      margin-top: $marginTop100;
    }
  }
  
  @-moz-keyframes topToBottom {
    0% {
      margin-top: -30%;
    }
    100% {
      margin-top: $marginTop100;
    }
  }
  
  @-webkit-keyframes topToBottom {
    0% {
      margin-top: -30%;
    }
    100% {
      margin-top: $marginTop100;
    }
  }
  
  @-o-keyframes topToBottom {
    0% {
      margin-top: -30%;
    }
    100% {
      margin-top: $marginTop100;
    }
  }
  
  @-ms-keyframes topToBottom {
    0% {
      margin-top: -30%;
    }
    100% {
      margin-top: -$marginTop100;
    }
  }

  @media screen and (max-width: 767px) {
    #topToBottom {
        animation: topToBottom linear 0s;
        -webkit-animation: topToBottom linear 0s;
        -moz-animation: topToBottom linear 0s;
        -o-animation: topToBottom linear 0s;
        -ms-animation: topToBottom linear 0s;
    }
    @keyframes topToBottom {
        0% {
          margin-top: 0%;
        }
        100% {
          margin-top: 0%;
        }
      }
      
      @-moz-keyframes topToBottom {
        0% {
          margin-top: 0%;
        }
        100% {
          margin-top: 0%;
        }
      }
      
      @-webkit-keyframes topToBottom {
        0% {
          margin-top: 0%;
        }
        100% {
          margin-top: 0%;
        }
      }
      
      @-o-keyframes topToBottom {
        0% {
          margin-top: 0%;
        }
        100% {
          margin-top: 0%;
        }
      }
      
      @-ms-keyframes topToBottom {
        0% {
          margin-top: 0%;
        }
        100% {
          margin-top: 0%;
        }
      }
  }

div.cards {
    height: auto;
    width: 100vw;
    background-color: $alt2;
    display: flex;
    justify-content: center;
    align-items: center;
    div.cards-container {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        flex-wrap: wrap;
          div.card-container {
            z-index: 1;
            height: auto;
            width: 60vw;
            margin: 20px 0px;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 0 15px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            -webkit-transition: transform 1s ease-in-out;
            -moz-transition: transform 1s ease-in-out;
            -ms-transition:transform 1s ease-in-out;
            -o-transition: transform 1s ease-in-out;
            transition: transform 1s ease-in-out;
            .circle-icon {
              background: $alt3;
              color: white;
              width: 5vh;
              height: 5vh;
              border-radius: 50%;
              text-align: center;
              line-height: 5vh;
              vertical-align: middle;
              font-size: 1.2em;
              margin: 20px 0;
            }
            h1 {
                font-family: $font-alt;
                font-size: 1em;
                color: $alt;
                font-weight: bolder;
                text-transform: uppercase;
                padding-bottom: 5px;
                text-align: center;
                padding: 0 15px;
            }
            p {
                font-family: $font-alt;
                font-size: 0.9em;
                color: $text;
                text-align: center;
                margin: 8px;
                padding: 0 20px;
                padding-bottom: 10px;
            }
          }
          div.card-container:first-of-type {
            margin-top: -100px
          }
        
        
      }
    }

@media screen and (min-width: 768px) {
    div.cards {
      height: auto;
        div.cards-container {
         height: auto;
         width: 90%;
         flex-direction: row;
         margin-bottom: 20px;     
            div.card-container {
              width: 20vw;
              height: 23vh;
              min-height: 200px;
              padding: 0 0;
              margin: 20px;
              margin-top: 0px;
              flex-basis: 25%;
              h1 {
                font-size: 0.9em;
              }
            }
            div.card-container:first-of-type {
              margin-top: -35px
            }
            div.card-container:hover{
              transform: translateY(-160px)
          }
          div.first-row {
            margin-top: -35px;
          }
          
      }
    }
   }

  @media screen and (min-width: 992px) {
   div.cards {
       div.cards-container {        
        width: 95%;
          div.card-container {
            height: 20vh;
            width: 15vw;
            min-width: 145px;
            min-height: 260px;
            margin: 15px;
            flex-basis: 10%;
            margin-top: -35px;
            .circle-icon {
              width: 4vh;
              height: 4vh;
              line-height: 4vh;
              font-size: 1.2em;
            }          
           }
           div.card-container:hover{
            transform: translateY(-160px)
           }
       }
    }
  }
  
  @media screen and (min-width: 1200px) {
    div.cards {
        div.cards-container {           
              div.card-container {
                height: 24vh;
                min-width: 170px;
                min-height: 250px;
                margin: 20px;
                margin-top: -35px;
                flex-basis: 13%;
                .circle-icon {
                  width: 5vh;
                  height: 5vh;
                  line-height: 5vh;
                  font-size: 1.4em;
                }  
                h1 {
                  font-size: 1em;
                }
              }
              div.card-container:hover{
                transform: translateY(-160px)   
              }
          }
      }
  }
