@keyframes zoom-in-slider-text {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@-moz-keyframes zoom-in-slider-text {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes zoom-in-slider-text {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}




section.banner{
  height: calc(100vh - 80px);
  width: 100vw;
  background-color: black;
  div.banner-slider {
    div.slider {
      height: calc(100vh - 80px);
      width: auto;
      background-size: cover;
      background-position: top-center;
      opacity: 0.7;
      background-color: black;
    }
    div.slider-text-container {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 80px);
      width: 100%;
      span.slider-text {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        font-family: $font-alt;
        font-size: 1.3em;
        font-weight: bold;
        color: white;
        width: 50%;
        text-align: center;
      }
    }
  }
 
 
  
  .slick-list {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  }
  .slick-prev {
      z-index: 1;
      left: 7% !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
  }
  .slick-next {
      z-index: 1;
      right: 7% !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
  }
  .slick-next::before, .slick-prev::before{
      content: "" !important;
  }
  .slick-dots, li button:before{
    bottom: 5%;
    z-index: 3;
    .slick-active {
      color: white;
    }
  }
  
}
.slick-active {
  div.banner-slider {
    div.slider-text-container {
      span.slider-text {
        animation: 1s ease-in-out 0s 1 zoom-in-slider-text;
      }
    }
  }
}

@media screen and (min-width: 748px) {
  section.banner {
    height: calc(100vh - 160px);
    width: 100vw;
    div.banner-slider {
      div.slider {
        height: calc(100vh - 160px);
      }
      div.slider-text-container {
        height: calc(100vh - 160px);
        font-size: 1.6em;
        }
      }
  }
}

@media screen and (min-width: 992px) {
  section.banner {
    height: calc(100vh - 80px);
    width: 100vw;
    div.banner-slider {
      div.slider {
        height: calc(100vh - 80px);
      }
      div.slider-text-container {
        height: calc(100vh - 80px);
        font-size: 1.6em;
        }
      }
    img {
      width: 100%;
      height: auto;
      object-position: center;
    }
    span.slider-text{
        top: 40%;
        font-size: 2.0em;
    }
    .slick-dots, li button:before{
      bottom: 15% !important;
      z-index: 3;
    }
  }
  
}


@media screen and (min-width: 1200px) {
  section.banner {
    height: calc(100vh - 80px);
    div.banner-slider {
      div.slider {
        height: calc(100vh - 80px);
      }
      div.slider-text-container {
        height: calc(100vh - 80px);
        font-size: 1.6em;
        }
      }
    .slick-dots, li button:before{
      bottom: 25% !important;
      z-index: 3;
      color: white
    }
    .slick-active {
      color: white !important;
    }
    .slick-next {
      z-index: 1;
      right: 9% !important;
    }
  }
  
}