@keyframes zoom-in {
    0% {
        height: 4%;
        width: 23%;
    }
    30% {
        height: 16%;
        width: 92%;
    }
    100% {
        height: 16%;
        width: 92%;
    }
}

@keyframes loading {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.hide {
    display: none;
}
section.banner-loading {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    animation: 4s ease-out 0s 1 loading;
    background-color: white;
    z-index: 5;
    div.banner-loading-background {
        height: 100%;
        width: 100%;
        background-position: top;
        background-size: cover;
    }
    div.banner-loading-logo {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateY(-50%);
        transform: translateX(-50%);
        height: 8%;
        width: 20%;
        background-image: url("./../assets/commsoft_logo.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
        animation: 4s ease-out 0s 1 zoom-in;
    }
}

@media screen and (min-width: 768px) {
    section.banner-loading {
        div.banner-loading-logo {

        }
    }
}