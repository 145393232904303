section.contact{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw;
    position: relative;
    background-color: $alt;
    background-repeat: no-repeat;
    background-size: 100%;
    div.contact-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100vw;
      padding-bottom: 70px;
      margin-top: 70px;
      h1 {
        font-family: $font-alt;
        font-size: 1.5em;
        font-weight: bold;
        color: $alt2;
        padding-bottom: 30px;
        text-transform: uppercase;
        letter-spacing: 1.1px;
      }
      div.contact-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 80%;
        div.contact-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          i.fas {
            color: white;
            font-size: 30px;
          }
          h2 {
            color: $alt2;
            padding: 20px 0;
          }
          p {
            width: 100%;
            font-family: $font-alt;
            font-weight: 300;
            text-align: center;
            font-size: 1.2em;
            color: $alt2;
          }
          p:first-of-type {
            padding-top: 15px;
          }
          a {
            width: 100%;
            font-family: $font-alt;
            font-weight: 300;
            text-align: center;
            font-size: 1.2em;
            color: $alt2;
            text-decoration: none;
 
          }
          a:first-of-type {
            padding-top: 15px;
          }
        }  
      }
    }
}
  
.link-like-hovering:hover{
  cursor: pointer;
}

  @media screen and (min-width: 768px) {
    section.contact{
      div.contact-container {
        h1 {
          font-size: 1.8em;
        }
        div.contact-content {
          flex-direction: row;
          align-items: flex-start;
          flex-wrap: wrap;
          div.contact-box {
            margin: 40px 30px;
            i.fas {
              font-size: 50px;
            }
            h2 {
              font-size: 1.3em;
              padding: 10px 0;
            }
            p {
              font-size: 1.2em;
            }
            a {
              font-size: 1.2em;
            }
          }  
        }
      }
    }
  }

@media screen and (min-width: 992px) {
  section.contact{
    div.contact-container {
      width: 95%;
      h1 {
        font-size: 1.6em;
        padding: 20px 0;
      }
      div.contact-content {
        div.contact-box {
          p {
            font-size: 1em;
          }
          a {
            font-size: 1em;
          }
        }  
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  section.contact{
    div.contact-container {
      h1 {
        font-size: 1.8em;
      }
      div.contact-content {
        div.contact-box {
          p {
            font-size: 1.2em;
          }
          a {
            font-size: 1.2em;
          }
        }  
      }
    }
  }
}