section.service {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100vw;
  position: relative;
  background-color: $alt2;
  div.service-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100vw;
    padding-bottom: 70px;
    padding-top: 70px;
    position: relative;
    div.service-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url("./../assets/service_background.jpg");
      background-size: cover;
      background-position: top;
      opacity: 0.7;
      background-color: white;
    }
    div.service-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      h1 {
        font-family: $font-alt;
        font-size: 1.5em;
        font-weight: bold;
        color: $text;
        text-transform: uppercase;
        letter-spacing: 1.1px;
        z-index: 1;
      }
      p {
        width: 50%;
        font-family: $font-alt;
        font-weight: 300;
        font-size: 1.2em;
        color: $text;
        padding: 20px 0;
        text-align: center;
      }
      div.service-box-container {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        div.service-box {
          height: auto;
          width: 300px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: white;
          box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
          margin: 40px;
          padding: 20px;
          transition: transform .2s ease;
          i {
            font-size: 3.2em;
            color: $alt3;
            padding: 15px;
          }
          h2 {
            font-family: $font-alt;
            font-size: 1.1em;
            color: $alt;
            font-weight: 1000;
            text-align: center;
            text-transform: uppercase;
          }
          p {
            
            width: 90%;
            font-family: $font-alt;
            font-size: 1em;
            color: $text;
            text-align: center;
            margin: 0 auto;
            
          }
        }
      }
      div.service-box:hover {
        transform: scale(1.1);
      }
      div.service-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
          font-family: $font-alt;
          color: $text;
          font-size: 1.3em;
          margin: 40px;
          text-align: center;
        }
        a {
          padding: 35px 45px;
          color: white;
          background-color: $alt;
          font-family: $font-alt;
          font-size: 1.1em;
          font-weight: bold;
          box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
          transition: transform .2s ease;
        } 
        a:hover {
          background-color: $alt3;
          transform: scale(1.1);
        }
      }
    }
  } 
}

@media screen and (min-width: 768px) {
  section.service {
    div.service-container {
      div.service-content {
        h1 {
          font-size: 1.8em;
        }
        p {
          font-size: 1.2em
        }
        div.service-box-container {
          div.service-box {
            height: auto;
          }
        }

        div.service-link {
          div.service.img {
            height: 22vh;
            width: 9vw;
          }
        }
      } 
    }
  }
}

@media screen and (min-width: 992px) {
  section.service {
    div.service-container {
      div.service-content {
        h1 {
          font-size: 1.6em;
        } 
        p {
          font-size: 1em;
        }
        width: 90%;
        div.service-box-container {
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          height: auto;
          div.service-box {
            height:auto;
            width: 250px;
            
            p {
              font-size: 1em;

            }
          }
        }
        div.service-link {
          div.service-img {
            height: 20vh;
            width: 8vw;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  section.service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    position: relative;
    background-color: $alt2;
    div.service-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      padding-bottom: 70px;
      padding-top: 70px;
      position: relative;
      
      div.service-background {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("./../assets/service_background.jpg");
        background-size: cover;
        background-position: top;
        opacity: 0.7;
        background-color: white;
      }
      div.service-content {
        h1 {
          font-size: 1.8em;
        }
        p {
          font-size: 1.2em;
        }
        div.service-box-container {
          div.service-box {
            width: 80%;
            max-width: 350px;
            i {
              font-size: 3.3em;
            }
            h2 {
              font-size: 1.3em;
            }
            p {
              font-size: 1.1em;
            }
          }
        }
      }
    } 
  }
}