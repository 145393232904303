div.lightmind {
  width: 100vw;
  height: auto;
  background-color: transparent;
  div.lightmind-content {
    position: absolute;
    top: 31vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: $font-alt;
    color: $text;
    background-color: transparent;
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  div.lightmind {
    height: 100vh;
  }
}


@media screen and (min-width: 992px) {
  div.lightmind {
    div.lightmind-content {
      height: auto;
      min-height: 84%;
    }
    footer {
      div.footer-container {
        div.footer-copyrights {
          p {
            font-size: 1em;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 1200px) {

  footer {
    div.footer-container {
      div.footer-copyrights {
        p {
          font-size: 1.2em;
        }
      }
    }
  }
}   