section.about {
  display: flex;
  height: auto;
  width: 100vw;
  position: relative;
  background-color: white;
  div.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    h2 {
        font-family: $font-alt;
        font-size: 1.5em;
        font-weight: bold;
        color: $alt;
        text-transform: uppercase;
        letter-spacing: 1.1px;
        margin-bottom: 30px;
      }
    div.about-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div.about-pic {
        display: flex;
        background-image: url("./../assets/about.jpg");
        background-position: left;
        height: 40vh;
        width: 80vw;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
      }
      div.about-text{
      height: auto;
      width: 80%;
      margin-bottom: 30px;
        p {
          font-family: $font-alt;
          font-weight: 300;
          text-align: left;
          font-size: 1.2em;
          padding-top: 30px;
          color: $text;
        }
      }
    }
  }
}


@media screen and (min-width: 768px) {
  section.about {  
    div.about-container {
      h2 {
        font-size: 1.8em;
      }
      div.about-content {
        div.about-pic {
          height: 35vh;
          width: 55vw;
          background-position: bottom;
        }
        div.about-text{
            p {
              font-size: 1.2em;
            }
          }
      }
    }
  }
}


@media screen and (min-width: 992px) {
  section.about {  
    div.about-container {
      margin: 70px 0;
      h2 {
        font-size: 1.6em;
      }
      div.about-content {
        flex-direction: row;
        justify-content: center;
        width: 70%;
        div.about-pic {
          height: 30vh;
          width: 29vw;
          background-position: center;
        }
        div.about-text{
          width: 50%;
          margin-left: 50px;
            p {
              font-size: 1em;
            }
          }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  section.about {  
    div.about-container {
      h2 {
        font-size: 1.8em;
      }
      div.about-content {
        div.about-text{
            p {
              font-size: 1.2em;
            }
          }
      }
    }
  }
}