section.software {
    background-image: url("./../../assets/service_background.jpg");
    height: auto;
    width: 100vw;
    background-size: cover;
    background-position: center;
    display: flex;
    div.software-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
        h2 {
            font-family: $font-alt;
            font-size: 1.5em;
            font-weight: bold;
            color: $alt;
            text-transform: uppercase;
            letter-spacing: 1.1px;
        }
        div.software-content {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 50px;
            p {
                font-family: $font-alt;
                font-weight: 300;
                font-size: 1.2em;
                padding-top: 30px;
                color: $text;
                ul {
                    list-style: disc;
                    font-weight: 300;
                padding-top: 10px;
                color: $text;
                    li {
                        margin-left: 40px;
                        padding-left: 15px;
                    }
                }
            }
            div.software-img {
                background-image: url('./../../assets/about.jpg');
            background-size: cover;
            background-position: center;
            height: 30vh;
            width: 70vw;
            margin: 25px;
            box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
            }
        }
    }
}

  @media screen and (min-width: 992px) {
    section.software {
        div.software-container {
            div.software-content {
                div.software-img {
                    height: 30vh;
                    max-height: 350px;
                    width: 30vw;
                    min-width: 480px;
                }
            }
        }
    }
}