footer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8%;
  width: 100vw;
  position: relative;
  background-color: $alt2;
  background-repeat: no-repeat;
  background-size: 100%;
  div.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100vw;
    margin: 2% 20%;  
    div.footer-logo {
      a {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(./../assets/commsoft_logo.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 10vh;
        width: 40vw;
      }
    }
    div.footer-copyrights {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 25%;
      p {
        font-family: $font-alt;
        color: $text;
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  footer{
    div.footer-container {
      flex-direction: row; 
      div.footer-logo {
        a {
          top: 20%;
          left: 28%;
          height: 5vh;
          width: 25vw;
        }
      }
      div.footer-copyrights {
        margin-top: 2%;
        margin-left: 70%;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  footer{
    div.footer-container {
      flex-direction: row; 
      div.footer-logo {
        a {
          top: 27%;
          left: 28%;
          height: 5vh;
          width: 25vw;
        }
      }
      div.footer-copyrights {
        margin-top: 0;
        margin-left: 70%;
        p {
          font-size: 1em;
        }
      }
    }
  }
}

