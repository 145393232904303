section.hardware {
    background-color: white;
    height: auto;
    width: 100vw;
    display: flex;
    background-color: white;
    div.hardware-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
        h2 {
            font-family: $font-alt;
            font-size: 1.5em;
            font-weight: bold;
            color: $alt;
            text-transform: uppercase;
            letter-spacing: 1.1px;
        }
        div.hardware-content {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 50px;
            div.hardware-img {
                background-image: url('./../../assets/IMG_20211005_105158.jpg');
                background-size: cover;
                background-position: center;
                height: 20vh;
                width: 80vw;
                margin: 25px;
                box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
            }
            p {
                font-family: $font-alt;
                font-weight: 300;
                font-size: 1.2em;
                color: $text;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    section.hardware {
        div.hardware-container {
            div.hardware-content {
                div.hardware-img {
                    height: 20vh;
                    width: 45vw;
                }
            }
        }
    }
  }
  
  
  @media screen and (min-width: 992px) {
    section.hardware {
        div.hardware-container {
            h2 {
                padding-bottom: 20px;
            }
            div.hardware-content {
                flex-direction: row;
                div.hardware-img {
                    height: 30vh;
                    max-height: 350px;
                    width: 30vw;
                }
                p {
                    width: 60%;
                    padding-left: 30px;
                }
            }
        }
    }
  }
