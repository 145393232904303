section.team{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100vw;
    position: relative;
    background-color: $alt2;
    background-repeat: no-repeat;
    background-size: 100%;
    div.team-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      width: 100vw;
      padding-bottom: 40px;
      margin-top: 70px;
      h1 {
        font-family: $font-alt;
        font-size: 1.5em;
        font-weight: bold;
        color: $text;
        padding-bottom: 30px;
        text-transform: uppercase;
        letter-spacing: 1.1px;
      }
      div.team-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 80%;
        div.team-member {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;
          div.team-img {
            display: flex;
            height: 160px;
            width: 160px;
            background-position: center;
            background-size: cover;
            border-radius: 50%;
          }
          h2 {
            font-family: $font-alt;
            font-size: 1.2em;
            font-weight: bold;
              color: $text;
              text-align: center;
              padding: 5px 0;
              padding-top: 15px;
          }
          p {
            width: 100%;
            font-family: $font-alt;
            font-weight: 300;
            text-align: center;
            font-size: 1em;
            color: $text;
               
          }
        }
        div.team-member:first-of-type {
          div.team-img {
            background-image: url("./../assets/Tomek.jpg");
          }
        }
        div.team-member:nth-of-type(2n) {
          div.team-img {
            background-image: url("./../assets/Janek.jpg");
          }
        }
        div.team-member:nth-of-type(3n) {
          div.team-img {
            background-image: url("./../assets/20210217_0158_ok.jpg");
          }
        }
      }
    }
  }
  

  @media screen and (min-width: 768px) {
    section.team{
      div.team-container {
        h1 {
          font-size: 1.8em;
        }
        div.team-content {
          div.team-member {
            div.team-img {
              background-position: center;
              height: 130px;
              width: 130px;
            }
            h2 {
              font-size: 1.3em;    
            }
            p {
              font-size: 1.1em;    
            }
          }
        }
      }
    }
}

@media screen and (min-width: 992px) {
  section.team{
    div.team-container {
      h1 {
        font-size: 1.6em;
        padding-top: 1%;
      }
      div.team-content {
        flex-direction: row;
        width: 70%;
        div.team-member {
          width: 33%;
          padding: 0 20px;
          div.team-img {
            background-position: top;
            background-size: cover;
          }
          p {
            width: 85%;
            font-size: 1em;    
          }
          h2 {
            font-size: 1.2em;
          }
        }
      }
    }
  }
}


@media screen and (min-width: 1200px) {
  section.team{
    div.team-container {
      h1 {
        font-size: 1.8em;
      }
      div.team-content {
        width: 60%;
        div.team-member {
          h2 {
            font-size: 1.2em;
          }
          p {
            font-size: 1.1em;    
          }
        }
      }
    }
  }
}