section.wifi {
    height: auto;
    width: 100vw;
    background-color: $alt2;
    display: flex;
    div.wifi-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 50px;
        h2 {
            font-family: $font-alt;
            font-size: 1.5em;
            font-weight: bold;
            color: $alt;
            text-transform: uppercase;
            letter-spacing: 1.1px;
            text-align: center;
        }
        div.wifi-content {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 50px;
            div.wifi-img {
                background-image: url('./../../assets/Wi-Fi_4_high-res.png');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-color: white;
                height: 160px;
                width: 160px;
                margin: 25px;
                box-shadow: rgba(0, 0, 0, 0.35) 5px 5px 15px;
            }
            div.wifi-text {
                p {
                    font-family: $font-alt;
                    font-weight: 300;
                    font-size: 1.2em;
                    padding-top: 30px;
                    color: $text;
                }
            }
        }
    }
}

  @media screen and (min-width: 992px) {
    section.wifi {
        div.wifi-container {
            div.wifi-content {
                flex-direction: row;
                position: relative;
                div.wifi-text {
                    width: 70%;
                }
            }
        }
    }
  }