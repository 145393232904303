div.trans-buttons-container {
    position: absolute;
    display: flex;
    z-index: 3;
    right: 2%;
    top: 2%;
    background-color: transparent;
    div.trans-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            width: 20px;
            height: 10px;
            content: "";
            background-size: cover;
            background-repeat: no-repeat;
            margin: 0 3px;
        }
        button:first-of-type {
            
            background-image: url("https://flagcdn.com/w20/pl.png");
        }
        button:last-of-type {
            background-image: url("https://flagcdn.com/w20/gb.png");
        }
        button:hover {
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 768px) {
    div.trans-buttons-container {
        div.trans-buttons {
            button {
                border: 1px solid $alt2;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    div.trans-buttons-container {
        position: fixed;
    }
}
